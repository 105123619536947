import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import URL from './url';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  REST_API: string = URL.url_Noapi;
  constructor(private storage:Storage, 
    private router: Router,private translate: TranslateService,
    private http: HttpClient) { 
      this.storage.create();
      this.loadUser();
  }

  loadUser(){
    this.storage.get('user').then(res=>{
      if(res){
        this.currentUser.next(res);
      }else{
        this.currentUser.next(false);
      }
    });
  }

  setUser(user){
    console.log(user)
    this.currentUser.next(user);
  }

  getUser(){
    return this.currentUser.asObservable();
  }

  getCurrentUser(){
    return JSON.parse(localStorage.getItem('user'));
  }

  getCurrentUserPermission(){
    return JSON.parse(localStorage.getItem('userPermission'));
  }

  setLanguage(lang:string){
    this.storage.set('Language', lang); 
  }

  getLanguage(){
    return this.storage.get('Language'); 
  }

  logOut(){
    this.storage.get('user').then(async res=>{
      await this.storage.clear();
      localStorage.clear();
      this.currentUser.next(false);
      this.router.navigate(['/login'], {replaceUrl:true})
    });
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
