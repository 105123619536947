import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';

import { Storage } from '@ionic/storage';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import {FileOpener} from '@ionic-native/file-opener/ngx'
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { HttpRequestInterceptor } from './httpRequestInterceptor';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';


import { IonicSelectableModule } from 'ionic-selectable';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent,FilterModalComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    ReactiveFormsModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MDBBootstrapModulesPro.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    IonicSelectableModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },Storage,{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },FileOpener],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}