import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import URL from './url';
@Injectable({
  providedIn: 'root'
})
export class TaskManagementService {
  REST_API: string = URL.url_api;
  currentUser = this.authService.getUser();

  constructor(private httpClient: HttpClient, private authService:AuthService) {}

  getAllProjects(): Observable<any> {
    return this.httpClient.get(this.REST_API+'/projects/findAllProjects')
      .pipe(
        catchError(this.handleError)
      )
  }

  getDeletedProjects(): Observable<any> {
    return this.httpClient.get(this.REST_API+'/projects/findAllDeletedProjectsWithActiveTask')
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllCategories(projectList): Observable<any> {
    return this.httpClient.get(this.REST_API+'/categories/findAllCategoriesByProject/'+projectList)
      .pipe(
        catchError(this.handleError)
      )
  }

  getComments(taskId): Observable<any> {
    return this.httpClient.get(this.REST_API+'/comments/findAllCommentsByTask/'+taskId)
      .pipe(
        catchError(this.handleError)
      )
  }

  getFilteredTask(data): Observable<any>{
    let API_URL = `${this.REST_API}/tasks/findFilteredTasks`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateTask(data,id): Observable<any>{
    let API_URL = `${this.REST_API}/tasks/updateTask/`+id;
    return this.httpClient.put(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getFilesAndDescription(taskId): Observable<any> {
    return this.httpClient.get(this.REST_API+'/taskFiles/findAllDocsByTask/'+taskId)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteFileTask(data): Observable<any>{
    let API_URL = `${this.REST_API}/taskFiles/deleteFileTask`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getTaskByKey(keyword:string) {
    if (!keyword) { return false; }
    let API_URL = `${this.REST_API}/tasks/findAllTasksByDescription`;
    return this.httpClient.get(API_URL+'/'+keyword).pipe(
      catchError(this.handleError)
    )
  }

  getTaskByEmployeeId(id:string): Observable<any> {
    let API_URL = `${this.REST_API}/tasks/findAllActiveTasksByUserId`;
    return this.httpClient.get(API_URL+'/'+id).pipe(
      catchError(this.handleError)
    )
  }

  deleteAllDeletedTasks(): Observable<any>{
    let API_URL = `${this.REST_API}/tasks/deleteAllTasks`;
    return this.httpClient.delete(API_URL).pipe(
      catchError(this.handleError)
    )
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
