import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../app.config';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  API_URL: string = APP_CONFIG.API_ENDPOINT;

  constructor(private httpClient: HttpClient,private storage:Storage) { }

  getAllUsers(): Observable<any> {
    let API_URL = `${this.API_URL}/users/findAllUsers`;
    return this.httpClient.get(API_URL)
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllUserswithCurrent(): Observable<any> {
    let API_URL = `${this.API_URL}/users/findAllUsersAndCurrentAdmin`;
    return this.httpClient.get(API_URL)
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllCurrnetUsers(): Observable<any> {
    let API_URL = `${this.API_URL}/users/findAllUsersByCurrentAdminId`;
    return this.httpClient.get(API_URL)
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllAdminsUsers(): Observable<any> {
    let API_URL = `${this.API_URL}/users/findAllAdmins`;
    return this.httpClient.get(API_URL)
      .pipe(
        catchError(this.handleError)
      )
  }

  addUser(data:any): Observable<any> {
    let API_URL = `${this.API_URL}/users/createUser`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateUser(data:any): Observable<any> {
    let API_URL = `${this.API_URL}/users/updateUser`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteUser(data:any): Observable<any> {
    let API_URL = `${this.API_URL}/users/deleteUser/`+data.id;
    return this.httpClient.delete(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  autoCompleteUserName(data:any): Observable<any> {
    let API_URL = `${this.API_URL}/users/autoCompleteUserName`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }


  addUserPermission(data:any): Observable<any> {
    let API_URL = `${this.API_URL}/permissions/createPermission`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateUserPermission(data:any): Observable<any> {
    let API_URL = `${this.API_URL}/permissions/updatePermission`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  findUserPermission(data:any): Observable<any> {
    let API_URL = `${this.API_URL}/permissions/findPermissionByUserId/`+data.id;
    return this.httpClient.get(API_URL)
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login') || error.message.includes('Unauthorized')){
      if(localStorage.getItem('user')){
        localStorage.clear();
        this.storage.clear();
        window.location.reload();
      }
    }

    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
