import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {addTaskInterFace} from '../interFaces/add-task-interFace';
import { AuthService } from './auth.service';
import URL from './url';

@Injectable({
  providedIn: 'root'
})
export class AddTaskService {

  REST_API: string = URL.url_api;
  currentUser = this.authService.getUser();
  constructor(private httpClient: HttpClient,private authService:AuthService){}

  addTask(data: addTaskInterFace): Observable<any> {
    let API_URL = `${this.REST_API}/tasks/createTask`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  uploadPhoto(data): Observable<any> {
    let API_URL = `${this.REST_API}/taskFiles/createTaskFile`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addOrFindProject(data): Observable<any> {
    let API_URL = `${this.REST_API}/projects/createProject`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addOrFindCategory(data,project_id): Observable<any> {
    let API_URL = `${this.REST_API}/categories/findOrCreateCategory`;
    data.project_id = project_id;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getEmbloyee(): Observable<any> {
    let API_URL = `${URL.url_api}/users/findAllUsers`;
    return this.httpClient.get(API_URL)
      .pipe(
        catchError(this.handleError)
      )
  }

  getProjectTitle(keyword:string) {
    if (!keyword) { return false; }
    let API_URL = `${this.REST_API}/projects/findProjectsByTitle`;
    return this.httpClient.get(API_URL+'/'+keyword).pipe(
      catchError(this.handleError)
    )
  }

  getCategoryTitle(keyword:string) {
    if (!keyword) { return false; }
    let API_URL = `${this.REST_API}/categories/findCategoriesByTitle`;
    return this.httpClient.get(API_URL+'/'+keyword).pipe(
      catchError(this.handleError)
    )
  }

  getAllMyTasks(page): Observable<any> {
    let API_URL = `${this.REST_API}/tasks/findAllCurrentUserTasks/`+page;
    return this.httpClient.get(API_URL).pipe(
      catchError(this.handleError)
    )
  }

  getAllTasks(page): Observable<any> {
    let API_URL = `${this.REST_API}/tasks/findAllTasks/`+page;
    return this.httpClient.get(API_URL).pipe(
      catchError(this.handleError)
    )
  }

  getTaskById(id): Observable<any>{
    let API_URL = `${this.REST_API}/tasks/findOneTask`;
    return this.httpClient.get(API_URL+'/'+id).pipe(
      catchError(this.handleError)
    )
  }

  addNewComment(data): Observable<any>{
    let API_URL = `${this.REST_API}/comments/createComment`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateTask(data): Observable<any>{
    let API_URL = `${this.REST_API}/tasks/updateTaskStatus`;
    return this.httpClient.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteTask(data): Observable<any>{
    let API_URL = `${this.REST_API}/tasks/deleteTask/`+data.taskId;
    return this.httpClient.delete(API_URL)
      .pipe(
        catchError(this.handleError)
      )
  }
  getPhoto(url) : Observable<any>{
    return this.httpClient.get(url,{
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
     }).pipe(
      catchError(this.handleError)
    );
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
