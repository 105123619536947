import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/users.service';
import { AddTaskService } from '../../services/add-task.service';
import { TaskManagementService } from '../../services/task-management.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})


export class FilterModalComponent implements OnInit {
  myForm: FormGroup;
  projectListFunction=this.taskManagementService.getAllProjects();
  projectList:any;
  projectId=[];
  categoryList:any;
  assigneeList:any;
  currentUser = this.authService.getCurrentUser();
  filter;
  filterValue;
  page: string;
  currentUserValue;
  isAdmin: boolean = true;
  currentUserPermission = this.authService.getCurrentUserPermission();
  public destroyed = new Subject<any>();
  
  constructor(public modalController: ModalController, private taskManagementService:TaskManagementService,
    public alertController: AlertController,
    public addTaskService:AddTaskService,
    private authService:AuthService, 
    private translate: TranslateService,
    private navParams:NavParams,
    private usersServices: UsersService,
    private loadingController: LoadingController) {
    
   }

  ngOnInit() {
    this.filterValue = this.navParams.get('filterValue');
    this.page = this.navParams.get('page');
    this.myForm = new FormGroup({
      project: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      assignee: new FormControl(null, Validators.required),
      supervisor: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
    });
    if(this.filterValue){
      this.myForm.patchValue({status:[this.filterValue.status]});
    }else{
      if(this.page == 'MYTASKS'){
        this.myForm.patchValue({status:['ACTIVE']});
      }else{
        this.myForm.patchValue({status:['All']});
      }
    }
    
    this.projectListFunction.subscribe(
      res=>{
        this.taskManagementService.getDeletedProjects().subscribe(del=>{
          res.data = res.data.concat(del.data);
          res.data.unshift({id:'All',value:'All',title:this.translate.instant('FILTER_PAGE.ALL')});
          if(this.filterValue){
            this.myForm.patchValue({project:this.filterValue.project});
          }else{
            this.myForm.patchValue({project:[{id:'All',value:'All',title:this.translate.instant('FILTER_PAGE.ALL')}]});
          }
          this.projectList=res.data;
        });
      },
      err=>{
        console.log(err);
      }
    );

    this.taskManagementService.getAllCategories(['All']).subscribe(
      category=>{
        category.unshift({id:'All',value:'All',title:this.translate.instant('FILTER_PAGE.ALL')})
        if(this.filterValue){
          this.myForm.patchValue({category:this.filterValue.category});
        }else{
          this.myForm.patchValue({category:[{id:'All',value:'All',title:this.translate.instant('FILTER_PAGE.ALL')}]});
        }
        this.categoryList=category;
      }
    );

    console.log(this.currentUserPermission)
    
    if(this.currentUser.role == "USER" ){
      this.isAdmin = false;
    }else{
      this.isAdmin = true;
    }

    this.usersServices.getAllCurrnetUsers().subscribe(employees =>{
      employees.unshift({id:'All',value:'All',userName:this.translate.instant('FILTER_PAGE.ALL')})
      this.currentUser.full_name= this.currentUser.userName;
      employees.push(this.currentUser)
      if(this.filterValue){
        this.myForm.patchValue({assignee:this.filterValue.assignee});
        this.myForm.patchValue({supervisor:this.filterValue.supervisor});
      }else{
        if(this.page == 'MYTASKS'){
          this.myForm.patchValue({assignee:[this.currentUser]});
        }else{
          this.myForm.patchValue({assignee:[{id:'All',value:'All',userName:this.translate.instant('FILTER_PAGE.ALL')}]});
        }
        this.myForm.patchValue({supervisor:[{id:'All',value:'All',userName:this.translate.instant('FILTER_PAGE.ALL')}]});
      }
      this.assigneeList = employees;
    });

  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onCloseProjectSelect(event: {
    component: IonicSelectableComponent,
    item: any,
    isSelected: boolean
  }) {
    var requset=true;
    this.projectId=[];
    let projects=event.component.value;
    event.component.value.forEach(async element => {
      if(element.id != 'All' && !this.projectId.includes('All')){
        this.projectId.push(element.id);
      }
      if(element.id === 'All'){
        if(event.component.value.length > 1){
          const alert = await this.alertController.create({
            header: this.translate.instant("ALERT_FILTER.ERROR"),
            message: this.translate.instant('ALERT_FILTER.ALL_ERROR'),
            buttons: [this.translate.instant('ALERT_FILTER.OK')],
          });
          await alert.present();
          this.myForm.patchValue({project:['']});
          requset=false;
        }
        this.projectId=[];
        this.projectId.push(element.id);
        return;
      }
    });
    let cat:any=[];
    cat.push({
      id:'All',
      value:'All',
      title:this.translate.instant('FILTER_PAGE.ALL')
    });
    for(var key in projects){
      for(var i in projects[key].projectCategories){
        cat.push(projects[key].projectCategories[i]);
      }
    }
    this.categoryList = cat;
  }

  onCloseCategorySelect(event: {
    component: IonicSelectableComponent,
    item: any,
    isSelected: boolean
  }) {
    // console.log(event);
  }

  onCloseAssigneeSelect(event: {
    component: IonicSelectableComponent,
    item: any,
    isSelected: boolean
  }) {
    // console.log(event.component);
  }

  onSelect(event: {
    component: IonicSelectableComponent,
    item: any,
    isSelected: boolean
  }) {
    event.component._selectedItems.forEach(elm=>{
      if(elm.id== 'All' && event.component._selectedItems.length > 1){
        event.component.toggleItems(false,event.component._selectedItems)
      }
    });
  }

  onCloseSupervisorSelect(event: {
    component: IonicSelectableComponent,
    item: any,
    isSelected: boolean
  }) {
    // console.log(event);
  }

  
  dismissModal(){
    this.modalController.dismiss(null, 'cancel');
  }

  async onSubmit(){
    const loading = await this.loadingController.create();
    await loading.present();
    let projectArr=[];
    let categoryArr=[];
    let assgineeArr=[];
    let supervisorArr=[];
    this.filter = {
      project:this.myForm.value.project,
      category:this.myForm.value.category,
      assignee:this.myForm.value.assignee,
      supervisor:this.myForm.value.supervisor,
      status:this.myForm.value.status,
    }
    this.myForm.value.project.forEach(elm => {
      projectArr.push(elm.id);
    });
    this.myForm.value.category.forEach(elm => {
      categoryArr.push(elm.id);
    });
    this.myForm.value.assignee.forEach(elm => {
      assgineeArr.push(elm.id);
    });
    this.myForm.value.supervisor.forEach(elm => {
      supervisorArr.push(elm.id);
    });

    this.myForm.value.project=projectArr;
    this.myForm.value.category=categoryArr;
    this.myForm.value.assignee=assgineeArr;
    this.myForm.value.supervisor=supervisorArr;
    if(this.myForm.valid){
      this.taskManagementService.getFilteredTask(this.myForm.value).subscribe(res=>{
        let data={data:res,
          filter:this.filter};
        loading.dismiss();
        this.modalController.dismiss(data, 'Filtered');
      },err=>{
        loading.dismiss();
        console.log(err);
      });
    }else{
      loading.dismiss();
      const alert = await this.alertController.create({
        header: this.translate.instant('ALERT_FILTER.ERROR'),
        message:this.translate.instant('ALERT_FILTER.ERROR_MESSAGE'),
        buttons: [this.translate.instant('ALERT_FILTER.OK')],
      });
      await alert.present();
    }
  }

  defultFilter(){
    if(this.page == "MYTASKS" ){
      this.myForm.patchValue({project:[{id:'All',value:'All',title:this.translate.instant('FILTER_PAGE.ALL')}]});
      this.myForm.patchValue({category:[{id:'All',value:'All',title:this.translate.instant('FILTER_PAGE.ALL')}]});
      this.myForm.patchValue({assignee:[this.currentUserValue]});
      this.myForm.patchValue({supervisor:[{id:'All',value:'All',full_name:this.translate.instant('FILTER_PAGE.ALL')}]});
      this.myForm.patchValue({status:["ACTIVE"]});
    }else{
      this.myForm.patchValue({project:[{id:'All',value:'All',title:this.translate.instant('FILTER_PAGE.ALL')}]});
      this.myForm.patchValue({category:[{id:'All',value:'All',title:this.translate.instant('FILTER_PAGE.ALL')}]});
      this.myForm.patchValue({assignee:[{id:'All',value:'All',full_name:this.translate.instant('FILTER_PAGE.ALL')}]});
      this.myForm.patchValue({supervisor:[{id:'All',value:'All',full_name:this.translate.instant('FILTER_PAGE.ALL')}]});
      this.myForm.patchValue({status:["All"]});
    }
    this.onSubmit();
  }

}
