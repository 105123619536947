import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private storage:Storage, private translate: TranslateService) { 
    this.storage.create();
  }
  selected = '';
  setInitialAppLanguage(){
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    this.storage.get('Language').then(val =>{
      if(val){
        this.setLanguage(val);
        this.selected = val;
      }
    });
  }

  getLanguage(){
    return this.storage.get('Language');
  }
  setLanguage(lan){
    console.log(lan);
    this.translate.use(lan);
    this.selected = lan;
    return this.storage.set('Language',lan);
  }
}
