import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { LogInService } from './services/log-in.service';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  token = this.loginService.getToken();

  constructor(private loginService: LogInService){}

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      if(!this.token){
        this.token = this.loginService.getToken();
      }
      if(this.token !== this.loginService.getToken()){
        this.token = this.loginService.getToken();
      }

      req = req.clone({
        headers: req.headers.append('Authorization', `Bearer ${this.token}`)
      });
      
      return next.handle(req);
  }
}