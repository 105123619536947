import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {logInInterface,verifiedLogIn} from '../interFaces/log-in-interface';
import URL from './url';
@Injectable({
  providedIn: 'root'
})
export class LogInService {

  REST_API: string = URL.url_api;

  constructor(private http: HttpClient) {}

  logIn(data: logInInterface): Observable<any> {
    let API_URL = `${this.REST_API}/users/login`;
    return this.http.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  verifiyCode(data: logInInterface): Observable<any> {
    let API_URL = `${this.REST_API}/verifyPropertyManagementUserLogin`;
    return this.http.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  cancelTheVerfication(data: logInInterface): Observable<any> {
    let API_URL = `${this.REST_API}/cancelPropertyManagementUserLogin`;
    return this.http.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  verifiedLogIn(data: verifiedLogIn): Observable<any> {
    let API_URL = `${this.REST_API}/propertyManagementUserLoginToOneAccount`;
    return this.http.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getToken(){
    var token = localStorage.getItem('token');
    return token;
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
