import { Component } from '@angular/core';
import { LanguageService } from './services/language.service';
import { FcmService } from './services/fcm.service';
import { Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private languageService: LanguageService,
    private platform: Platform,
    private fcmService: FcmService) {
    this.initialzeApp();
  }

  initialzeApp(){
    this.languageService.setInitialAppLanguage();
    
    if (this.platform.is('desktop') || this.platform.is('mobileweb')){
      // Do Something
    }
    // this.platform.ready().then(() => {
    //   // Trigger the push setup 
    //   this.fcmService.initPush();
    // });
    
    App.addListener('appStateChange', ({ isActive }) => {
      console.log('App state changed. Is active?', isActive);
    });
    
    App.addListener('appUrlOpen', data => {
      console.log('App opened with URL:', data);
    });
    
    App.addListener('appRestoredResult', data => {
      console.log('Restored state:', data);
    });

    App.addListener('backButton', data => {
      // return false;
      console.log('backButton:', data);
      if(data.canGoBack == false){
        App.exitApp()
      }else{
        window.history.back();
        return false;
      }
    });
  }
}
