import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router } from '@angular/router';
import { filter, take, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor (private authService:AuthService,private router:Router){}

  canActivate( route: ActivatedRouteSnapshot){
    return this.authService.getUser().pipe(
      filter(val=> val !== null),
      take(1),
      map(user=>{
        if(!user){
          return true;
        }else{
          return this.router.parseUrl('/menu/first');
        }
      })
    );
  }

  canLoad( route: Route){
    const permittedRoles: string[] = route.data.roles;
    const currentUserRole = this.authService.getCurrentUser()?.role;
    if(permittedRoles.includes(currentUserRole) ){
      return true;
    } else {
      return this.router.parseUrl('/login');
    }
  }
}
