import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router'
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service'
import URL from './url';;

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  REST_API: string = URL.url_api;
  currentUser = this.authService.getCurrentUser();
  constructor(private http: HttpClient,private authService:AuthService) { }

  initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    }
  }





  private registerPush() {
    console.log('Initializing HomePage');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        setTimeout(() => {
          if(this.currentUser){
            var data = {token:token.value,
            userId: this.currentUser.id};
            let API_URL = `${this.REST_API}/notificationTokens/createNotificationToken`;
            this.http.post(API_URL,data)
              .pipe(
                catchError(this.handleError)
              ).subscribe(res=>{
                console.log('TokenSaved',res);
              });
          }
        }, 500);
        console.log('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );

  }

  getNotification(): Observable<any> {
    return this.http.get(this.REST_API+'/notificationMessages/findAllNotificationMessagesByCurrentUserId')
      .pipe(
        catchError(this.handleError)
      )
  }

  getNotificationLength(): Observable<any> {
    return this.http.get(this.REST_API+'/notificationMessages/findLengthNotificationMessagesByCurrentUserId')
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteNotification(id:number){
    return this.http.delete(this.REST_API+'/notificationMessages/deleteNotificationMessage/'+id)
      .pipe(
        catchError(this.handleError)
      )
  }

  changeMessagesStauts(){
    return this.http.get(this.REST_API+'/notificationMessages/changeStatusNotificationMessageForCurrentUserId')
      .pipe(
        catchError(this.handleError)
      )
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
